<template>
  <div class="page">
    <div class="main">
      <div class="userlist">
        <div class="center">
          <ul class="boxlist"
              v-if="currentUserName">
            <li v-for="deviceData in marker"
                :key="deviceData.id"
                @click="choseDetails(deviceData)">
              <div class="img">
                <div class="state">
                  <span class="yuan"
                        :class="{'lixian':deviceData.status != 1}"></span>
                  <span>{{deviceData.status == 1 ? "在线":"离线"}}</span>
                </div>
                <p>
                  <span>{{deviceData.device_name}}</span>
                  <span>{{deviceData.device_type}}</span>
                </p>
              </div>
              <div class="con"
                   :class="{'conss':deviceData.status != 1}">
                <div class="disno">设备离线中,期待上线</div>
                <span>描述:</span>
                <p>{{ deviceData.remarks == null ? '暂无描述' : deviceData.remarks}}</p>
              </div>
            </li>
          </ul>
          <p class="nouser"
             v-if="!currentUserName">您还未登录,请 <span @click="goin()">登录</span> !</p>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import $cookies from "vue-cookies";
import { $device } from "@/services";
export default {
  name: "gispersonal",
  data () {
    return {
      // deviceDatas: {},
      marker: [],
      device_name: null,
      currentPage: 1,
      total: 0,
      currentUserName: '',
      checkTime: dayjs().subtract(2, "m").format("YYYYMMDDHHmm").concat("00"),// 时间
    };
  },
  created () {
    this.currentUserName = $cookies.get("currentUserName");
    if (this.currentUserName) {
      this.getLessonListHelper()
    }
  },

  methods: {
    goin () {
      this.$router.push("/login");
    },
    // getLatestData (device) {
    //   return $device.getDeviceData({
    //     device: device,
    //     time: this.checkTime,
    //     // time: "20230425085900",
    //   }).then((res) => {
    //     if (res.data.length != 0) {
    //       this.drawer = true
    //       this.deviceDatas = res.data.map(d => {
    //         return {
    //           tempf: d.tempf,
    //           tempinf: d.tempinf,
    //           totalrainin: d.totalrainin,
    //         }
    //       })[0]
    //     } else {
    //       this.drawer = false
    //     }
    //   });
    // },
    choseDetails (cur) {
      this.$router.push({
        name: "site",
        query: {
          device: cur.device
        }
      });
    },
    getLessonListHelper () {
      return $device.getConnectPage({
        device_name: this.device_name,
        currentUserName: $cookies.get("userInfo").userName,
        email: $cookies.get("userInfo").email,
        userId: $cookies.get("userInfo").id,
        page: this.currentPage,
        limit: 12
      }).then((res) => {
        if (res.result === 1 && res.data) {
          
          this.total = res.data.dataCount;
          this.marker = res.data.dataList.map(d => {
            return {
              id: d.id,
              mac: d.mac,
              device: d.device,
              device_name: d.deviceName,
              device_type: d.deviceType,
              position_lng: d.positionLng,
              position_lat: d.positionLat,
              position_alt: d.positionAlt,
              address: d.address,
              remarks: d.remarks,
              currentUserName: d.currentUserName,
              status: d.status
            }
          })
           
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.center {
  width: 1420px;
  margin: 0 auto;
}
@media (max-width: 1920px) {
  .center {
    width: 1420px;
  }
}

@media (max-width: 1680px) {
  .center {
    width: 1280px;
  }
}

@media (max-width: 1280px) {
  .center {
    width: 100%;
  }
}

.page {
  width: 100%;
  height: calc(100vh - 50px);

  .main {
    .userlist {
      .boxlist {
        padding: 50px 0;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        li {
          cursor: pointer;
          box-shadow: 0 1px 2px rgb(25 25 25 / 40%);
          color: #fff;
          text-shadow: 1px 1px 1px rgb(0 0 0 / 50%);
          width: 340px;
          margin: 10px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          a {
            color: #fff;
          }
          .img {
            width: 100%;
            height: 190px;
            padding: 15px;
            box-sizing: border-box;
            background-image: url(../../assets/bj-4.jpg);
            background-size: cover;
            background-repeat: no-repeat;
            display: flex;
            align-items: flex-end;
            position: relative;
            .state {
              position: absolute;
              top: 15px;
              right: 15px;
              width: 100px;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #ffffff56;
              padding: 5px;
              border-radius: 5px;
              .yuan {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: #01bd6e;
                margin-right: 5px;
              }
              .lixian {
                background-color: #666;
              }
            }

            p {
              display: flex;
              flex-direction: column;
              span {
                margin-bottom: 5px;
              }
            }
          }
          .con {
            background: linear-gradient(#006f99af, #006f99);
            height: 100px;
            .disno {
              display: none;
            }
            span {
              display: block;
              padding: 6px;
              font-size: 18px;
            }
            p {
              font-size: 16px;
              line-height: 19px;
              padding: 6px;
              text-indent: 19px;
              height: 62px;
              // white-space: nowrap;
              overflow: hidden; //溢出内容隐藏
              text-overflow: ellipsis; //文本溢出部分用省略号表示
            }
          }
          .conss {
            position: relative;
            .disno {
              color: #ffffff;
              display: block;
              width: 100%;
              background: linear-gradient(#858585, #c9c9c9);
              height: 100%;
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            top: 0;
          }
        }
      }
      .nouser {
        margin-top: 50px;
        font-size: 24px !important;
        span {
          color: #006f99;
        }
      }
    }
    .collectlist {
      padding: 20px 0;
      .collect_box_list {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        h3 {
          font-size: 34px;
          color: #666;
          margin: 20px 0;
        }
        .list {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          li {
            cursor: pointer;
            margin: 10px;
            width: 215px;
            box-shadow: 0 1px 2px rgb(25 25 25 / 40%);
            color: #fff;
            text-shadow: 1px 1px 1px rgb(0 0 0 / 50%);
            a {
              color: #fff;
            }
            .img {
              padding: 10px;
              box-sizing: border-box;
              background-image: url(../../assets/gis-personal/mor.png);
              background-size: 100% auto;
              background-repeat: no-repeat;
              display: flex;
              align-items: flex-end;
              position: relative;
              p {
                padding-top: 60px;
                display: flex;
                flex-direction: column;
              }
            }
            .con {
              padding: 15px 0;
              background: linear-gradient(#006f99af, #006f99);
              display: flex;
              justify-content: space-around;
              img {
                width: 30%;
                display: block;
              }
              .left {
                display: flex;
                flex-direction: column;
                justify-content: center;
              }
            }
          }
        }
      }
    }

    .xingzeng {
      .box {
        padding: 100px 20px;
        border-top: 1px solid #e3e3e3;
        button {
          font-size: 24px;
          background-color: #08b;
          color: #fff;
          padding: 15px 30px;
          margin-left: 50px;
        }
      }
    }
  }
}
</style>